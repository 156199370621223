import React from "react";
const AutoLink = ({ text }) => {
  const urlRegex = /(https?:\/\/[^\s]+|@\w+\.\w+)/g;
  const parts = text.split(urlRegex);
  return (
    <span className="text-wrap text-break">
      {parts.map((part, index) => {
        if (part.match(urlRegex)) {
          if (part.match(/@\w+\.\w+/)) {
            // Extract username.algo from part
            const usernameAlgo = part.substring(1); // removes '@' from the start
            const profileUrl = `https://soapblox.app/profile/${usernameAlgo}`;

            return (
              <a key={index} href={profileUrl} rel="noopener noreferrer">
                {part}
              </a>
            );
          } else {
            // Check if it's a regular URL or just text
            return part.match(urlRegex) ? (
              <a
                key={index}
                href={part}
                target="_blank"
                rel="noopener noreferrer"
              >
                {part}
              </a>
            ) : (
              <span key={index}>{part}</span>
            );
          }
        } else {
          return (
            <span
              key={index}
              dangerouslySetInnerHTML={{
                __html: part,
              }}
            ></span>
          );
        }
      })}
    </span>
  );
};
export default AutoLink;
