import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const Topic = ({ accountAddress, Data }) => {
  const [Topics, setTopics] = useState([]);
  function base64Decode(input) {
    return atob(input);
  }
  const getData = (data) => {
    const pairs = data.split(";");
    const keyValueObject = {};

    for (const pair of pairs) {
      const [key, value] = pair.split(":");
      if (key && value) {
        keyValueObject[key] = value;
      }
    }
    return keyValueObject;
  };
  const fetchJson = () => {
    Data.forEach((item) => {
      !Topics.includes(
        base64Decode(
          getData(base64Decode(item.note).replace("soapblox:", ""))?.topic
        )
      ) &&
        setTopics((Topics) => [
          ...Topics,
          base64Decode(
            getData(base64Decode(item.note).replace("soapblox:", ""))?.topic
          ),
        ]);
    });
  };
  useEffect(() => {
    fetchJson();
  });
  const uniqueTopics = Array.from(new Set(Topics));
  return (
    <>
      {uniqueTopics?.map((item) => (
        <Link
          key={item}
          to={"/topic/" + encodeURIComponent(item)}
          className="btn btn-primary btn-sm rounded-2 me-1 mb-1"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: item,
            }}
          ></span>
        </Link>
      ))}
    </>
  );
};

export default Topic;
