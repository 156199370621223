import React, { useEffect, useState } from "react";
import { SOAPBLOX_FEE, SOAPBLOX_WALLET } from "Root/config.js";
import { Link, useParams, useNavigate } from "react-router-dom";
import Detail from "../../components/detail/detail";
import sbLogo from "../../sb-logo.png";
//import errors from "eslint-plugin-import/config/errors";
const View = (accountAddress, devwall) => {
  const { txid } = useParams();
  const [data, setData] = useState();
  const [replies, setReplies] = useState();
  const [r404, setR404] = useState(false);
  const navigate = useNavigate();
  const fetchJson = () => {
    fetch(
      `https://mainnet-idx.algonode.cloud/v2/accounts/` +
        SOAPBLOX_WALLET +
        `/transactions?tx-type=pay&txid=${txid}` +
        "&address-role=receiver" +
        `&currency-greater-than=${SOAPBLOX_FEE - 1}` +
        `&currency-less-than=${SOAPBLOX_FEE + 1}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data["current-round"] && data.transactions.length > 0) {
          setData(data);
        } else {
          setR404(true);
        }
      });
  };
  const fetchJsonReplies = () => {
    fetch(
      `https://mainnet-idx.algonode.cloud/v2/accounts/` +
        SOAPBLOX_WALLET +
        `/transactions?tx-type=pay` +
        `&note-prefix=` +
        btoa("soapbloxreply:" + txid + ";") +
        "&address-role=receiver" +
        `&currency-greater-than=${SOAPBLOX_FEE / 2 - 1}` +
        `&currency-less-than=${SOAPBLOX_FEE / 2 + 1}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data["current-round"]) {
          setReplies(data);
        } else {
          setR404(true);
        }
      });
  };
  useEffect(() => {
    fetchJson();
    fetchJsonReplies();
    // eslint-disable-next-line
  }, []);
  function base64Decode(input) {
    return atob(input);
  }
  const handleBack = () => navigate(-1);
  const handleReload = () => {
    window.location.reload();
  };
  return (
    <>
      <div className="py-2 py-lg-5">
        <div className="bg-standard sticky-top z-1">
          <div className="rounded-top-4 border mb-n-1px p-3 p-lg-4 d-flex align-items-center justify-content-between">
            <button
              type="button"
              className="btn btn-outline-secondary me-3"
              onClick={handleBack}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </button>
            <div>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={handleReload}
              >
                <i className="fa-light fa-arrows-rotate"></i>
              </button>
              <img
                src={sbLogo}
                width="40"
                className="d-block d-lg-none ms-3 opacity-25"
                alt="SoapBlox"
              />
            </div>
          </div>
        </div>
        {!r404 ? (
          <>
            <div
              id="blox"
              className="border border-top-0 rounded-top-0 rounded-4 overflow-hidden"
            >
              {data?.transactions.map(
                (item) =>
                  item?.note &&
                  base64Decode(item.note).includes("soapblox:") &&
                  !base64Decode(item.note).includes("soapbloxreply:") && (
                    <Detail
                      key={item.id}
                      id={item.id}
                      wallet={item.sender}
                      message={item.note}
                      date={item["round-time"]}
                      accountAddress={accountAddress}
                      allowReply={true}
                    />
                  )
              )}
              {replies?.transactions && (
                <>
                  <div className="mx-3 mb-3 overflow-hidden border-top-0 border rounded-3">
                    {replies.transactions.map(
                      (item) =>
                        item?.note && (
                          <Detail
                            key={item.id}
                            id={txid}
                            wallet={item.sender}
                            message={item.note}
                            date={item["round-time"]}
                            accountAddress={accountAddress}
                            allowReply={false}
                          />
                        )
                    )}
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div id="blox" className="border rounded-4 overflow-hidden">
              <div className="p-3 p-lg-4">
                <h4 className="text-body-emphasis m-0">Oops...</h4>
              </div>
              <div className="p-3 p-lg-4 border-top">
                <p className="lead text-body-emphasis">
                  Well this is embarrassing...
                </p>
                <Link to="/" className="btn btn-primary rounded-pill px-4">
                  Back whence you came
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default View;
