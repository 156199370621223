import React, { useState } from "react";
//import { getLinkPreview } from "link-preview-js";
//import pfp from "../pfp.png";

const AutoPreview = ({ text }) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);
  const [preview, setPreview] = useState(false);
  const [previewData, setPreviewData] = useState(false);

  // eslint-disable-next-line array-callback-return
  parts.sort().map((part, index) => {
    if (part.match(urlRegex) && !preview) {
      setPreview(part.toString());
    }
  });
  if (
    preview &&
    !previewData &&
    preview.includes("https://www.youtube.com/watch?v=")
  ) {
    setPreviewData(
      "https://www.youtube.com/embed/" +
        preview.replace("https://www.youtube.com/watch?v=", "")
    );
    /*
    return previewData.siteName === "YouTube" &&
      previewData.mediaType === "video.other" ? (
      <div className="container mt-4 border rounded-4 p-2">
        <div className="ratio ratio-16x9">
          <iframe
            src={`https://www.youtube.com/embed/${previewData.url.replace(
              "https://www.youtube.com/watch?v=",
              ""
            )}`}
            title="YouTube video"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    ) : (
      <a
        href={previewData.url}
        className="btn btn-link"
        target="_blank"
        rel="noreferrer"
      >
        <span className="container d-block mt-4 border rounded-4 p-2">
          <span className="row align-items-center">
            <span className="col-4 col-lg-5">
              {previewData?.images ? (
                <img
                  src={previewData.images}
                  className="img-fluid rounded-4 w-100"
                  alt={previewData?.title}
                />
              ) : (
                <img
                  src={pfp}
                  className="img-fluid rounded-4"
                  alt={previewData?.title}
                />
              )}
            </span>
            <span className="col text-start">
              <span className="text-body-emphasis">
                {previewData?.siteName && (
                  <small className="d-block">{previewData.siteName}</small>
                )}{" "}
                {previewData?.title && previewData.title}
              </span>
              <span className="d-block">
                {previewData?.description && previewData.description}
              </span>
            </span>
          </span>
        </span>
      </a>
    );*/
  }
  if (preview && previewData) {
    return (
      <div className="container-fluid mt-4 border rounded-4 bg-darker p-3 shadow-inset">
        <div className="ratio ratio-16x9 shadow-sm">
          <iframe
            src={previewData}
            title="YouTube video"
            allowFullScreen
            className="w-100 rounded-3 "
          ></iframe>
        </div>
      </div>
    );
  }
};
export default AutoPreview;
