import React from "react";
import { SOAPBLOX_FEE, SOAPBLOX_WALLET } from "../config.js";
//import { useNavigate } from "react-router-dom";
import algosdk from 'algosdk';

import {
  useWallet,
  DEFAULT_NODE_BASEURL,
  DEFAULT_NODE_TOKEN,
  DEFAULT_NODE_PORT
} from '@txnlab/use-wallet';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const algodClient = new algosdk.Algodv2(DEFAULT_NODE_TOKEN, DEFAULT_NODE_BASEURL, DEFAULT_NODE_PORT)
export default function Transact({ note, reply, txid,dev }) {
  //const [hasNote, setHasNote] = useState(true);
  //const navigate = useNavigate();
  const { activeAddress, signTransactions, sendTransactions } = useWallet()
  const sendTransaction = async (from?: string, to?: string, amount?: number) => {
    toast.info("Signing & Sharing!", {
      position: "bottom-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
    try {
      if (!from || !to || !amount) {
        throw new Error('Missing transaction params.')
      }

      const suggestedParams = await algodClient.getTransactionParams().do()

      const transaction = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        from,
        to,
        amount,
        note: new Uint8Array(Buffer.from(note)),
        suggestedParams
      })
      
      const encodedTransaction = algosdk.encodeUnsignedTransaction(transaction)
      const signedTransactions = await signTransactions([encodedTransaction])
      const waitRoundsToConfirm = 4
      const { id } = await sendTransactions(signedTransactions, waitRoundsToConfirm)
      var shareModalId = document.getElementById("shareModal");
      var bodyId = document.getElementById("body");
      var modalBg = document.querySelectorAll(".modal-backdrop.fade.show");
      //console.log('Successfully sent transaction. Transaction ID: ', id);
      toast.success('Successfully sent transaction. Transaction ID: '+id, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
        setTimeout(function(){
        shareModalId?.classList.remove("show");
        bodyId?.classList.remove("modal-open");
        bodyId?.removeAttribute("style");
        modalBg.forEach(box => {
          box.remove();
        });
        txid ? (window.location.href=`/view/${txid}`):(window.location.href=`/view/${id}`)
      }, 3000);
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      //console.error(error)
    }
  }

  if (!activeAddress) {
    return <p>Connect an account first.</p>
  }

  return (
    <div>
      <button type="button" disabled={note ? false : true}
      className="btn btn-primary rounded-pill px-5"
      onClick={() => sendTransaction(activeAddress, SOAPBLOX_WALLET, SOAPBLOX_FEE)}
      >
        Sign &amp; Share
      </button>
      <ToastContainer />
    </div>
  )
}