import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SOAPBLOX_WALLET, SOAPBLOX_FEE } from "Root/config.js";
import Card from "../../components/card/card";
import sbLogo from "../../sb-logo.png";
import axios from "axios";
//import algosdk from "algosdk";
import WalletBar from "../../components/walletbar/walletbar";
//import Assets from "../../components/assets/assets";
const Profile = (accountAddress) => {
  const { user } = useParams();
  //const [walletAdr, setWalletAdr] = useState(user);
  const [Data, setData] = useState();
  const [niceName, setNicename] = useState(user);
  const [hasPosts, setHasPosts] = useState(false);
  const navigate = useNavigate();

  const fetchJson = useCallback(async () => {
    await axios
      .get(`https://api.nf.domains/nfd/${user}`)
      .then((response) => {
        if (!hasPosts) {
          if (response?.data?.depositAccount) {
            setNicename(response.data.depositAccount);
            //setWalletAdr(response.data.depositAccount);
            axios
              .get(
                "https://mainnet-idx.algonode.cloud/v2/transactions?tx-type=pay" +
                  `&address=${SOAPBLOX_WALLET}` +
                  `&sender=${response.data.depositAccount}` +
                  `&currency-greater-than=${SOAPBLOX_FEE - 1}` +
                  `&currency-less-than=${SOAPBLOX_FEE + 1}` +
                  "&note-prefix=c29hcGJsb3g6"
              )
              .catch((err) => {
                //console.log(err);
              })
              .then((response) => {
                if (response?.data?.transactions?.length > 0) {
                  const parsedData = { entries: response.data.transactions };
                  if (parsedData) {
                    //console.log(parsedData);
                    setData(parsedData);
                    setHasPosts(true);
                  }
                }
              });
          }
        }
      })
      .catch((err) => {
        if (!hasPosts) {
          axios
            .get(
              "https://mainnet-idx.algonode.cloud/v2/transactions?tx-type=pay" +
                `&address=${SOAPBLOX_WALLET}` +
                `&sender=${user}` +
                `&currency-greater-than=${SOAPBLOX_FEE - 1}` +
                `&currency-less-than=${SOAPBLOX_FEE + 1}` +
                "&note-prefix=c29hcGJsb3g6"
            )
            .catch((err) => {
              //console.log(err);
            })
            .then((response) => {
              if (response?.data?.transactions?.length > 0) {
                const parsedData = { entries: response.data.transactions };
                if (parsedData) {
                  //console.log(parsedData);
                  setData(parsedData);
                  setHasPosts(true);
                }
              }
            });
        }
      });
  }, [hasPosts, user]);
  useEffect(() => {
    fetchJson();
  }, [fetchJson]);

  function base64Decode(input) {
    return atob(input);
  }
  const handleBack = () => navigate(-1);
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="py-2 py-lg-5">
        <div className="bg-standard sticky-top z-1">
          <div className="rounded-top-4 border mb-n-1px p-3 p-lg-4 d-flex align-items-center justify-content-between">
            <button
              type="button"
              className="btn btn-outline-secondary me-3"
              onClick={handleBack}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </button>
            <div>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={handleReload}
              >
                <i className="fa-light fa-arrows-rotate"></i>
              </button>
              <img
                src={sbLogo}
                width="40"
                className="d-block d-lg-none ms-3 opacity-25"
                alt="SoapBlox"
              />
            </div>
          </div>
        </div>
        <div
          id="blox"
          className="border rounded-4 rounded-top-0 overflow-hidden"
        >
          <div className="p-3 p-lg-4 position-relative">
            <div className="row align-items-center">
              <WalletBar
                wallet={niceName}
                big={true}
                personal={false}
                activeAddress={accountAddress.accountAddress}
              />
            </div>
          </div>
          {Data?.entries.map(
            (item) =>
              item?.note &&
              item?.sender === niceName &&
              base64Decode(item.note).includes("soapblox:") &&
              !base64Decode(item.note).includes("soapbloxreply:") && (
                <Card
                  key={item.id}
                  id={item.id}
                  wallet={niceName}
                  message={item.note}
                  date={item["round-time"]}
                  accountAddress={niceName}
                />
              )
          )}
          {!Data && (
            <div className="p-3 p-lg-4 border-top">
              Nothing to see here, move along...
            </div>
          )}
          {/*
          <div className="p-3 p-lg-4 border-top">
            <div className="row align-items-center g-3">
              {!walletAdr.includes(".algo") && <Assets wallet={walletAdr} />}
            </div>
          </div>*/}
        </div>
      </div>
    </>
  );
};

export default Profile;
