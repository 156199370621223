import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import pfp from "../../nopfp.png";
import banner from "../../blankprofile.png";
import GiftModal from "../../components/modals/gift/gift";
// import { check } from "prettier";
//import { check } from "prettier";
//import algo from "../../icon_Algorand.png";
//import usdc from "../../icon_USDCa.png";
const WalletBar = ({
  wallet,
  big,
  personal,
  timesince,
  activeAddress,
  tiny,
}) => {
  const [walletVerified, setWalletVerified] = useState(false);
  const [walletName, setWalletName] = useState(shortenAddress(wallet));
  const [walletAvatar, setWalletAvatar] = useState(pfp);
  const [walletBanner, setWalletBanner] = useState(banner);
  const [walletBio, setWalletBio] = useState(false);
  const [walletTwitter, setWalletTwitter] = useState(false);
  const [walletDiscord, setWalletDiscord] = useState(false);
  const [walletTelegram, setWalletTelegram] = useState(false);
  const [walletGitHub, setWalletGitHub] = useState(false);
  const [walletDomain, setWalletDomain] = useState(false);
  const [walletWebsite, setWalletWebsite] = useState(false);
  const [walletEmail, setWalletEmail] = useState(false);
  const [walletLinkedIn, setWalletLinkedIn] = useState(false);
  const [checkedNFD, setCheckedNFD] = useState(0);
  const [loading, setLoading] = useState(true);
  //const navigate = useNavigate();
  const handleProfileOnClick = () => {
    if (walletName.includes(".algo")) {
      //navigate(`/profile/${walletName}`);
      window.location.href = `/profile/${walletName}`;
    } else {
      //navigate(`/profile/${wallet}`);
      window.location.href = `/profile/${wallet}`;
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchNFD = useCallback(() => {
    if (wallet && !wallet.includes(".algo") && checkedNFD === 0) {
      setCheckedNFD(checkedNFD + 1);
      axios
        .get(`https://api.nf.domains/nfd/lookup?address=${wallet}&view=full`)
        .then((response) => {
          if (response.status === 200) {
            if (response.data) {
              if (
                response.data[wallet]?.properties?.verified?.twitter ||
                response.data[wallet]?.properties?.verified?.discord ||
                response.data[wallet]?.properties?.verified?.telegram ||
                response.data[wallet]?.properties?.verified?.github ||
                response.data[wallet]?.properties?.verified?.email ||
                response.data[wallet]?.properties?.verified?.domain
              ) {
                //setHasNFD(true);
                setWalletVerified(true);
              } else {
                //setHasNFD(false);
                setWalletVerified(false);
              }
              // Set Twitter
              if (response.data[wallet]?.properties?.verified?.twitter) {
                setWalletTwitter(
                  response.data[wallet]?.properties?.verified?.twitter
                );
              }
              // Set Discord
              if (response.data[wallet]?.properties?.verified?.discord) {
                setWalletDiscord(
                  response.data[wallet]?.properties?.verified?.discord
                );
              }
              // telegram
              if (response.data[wallet]?.properties?.verified?.telegram) {
                setWalletTelegram(
                  response.data[wallet]?.properties?.verified?.telegram
                );
              }
              //github
              if (response.data[wallet]?.properties?.verified?.github) {
                setWalletGitHub(
                  response.data[wallet]?.properties?.verified?.github
                );
              }
              //linkedin
              if (response.data[wallet]?.properties?.verified?.linkedin) {
                setWalletLinkedIn(
                  response.data[wallet]?.properties?.verified?.linkedin
                );
              }
              //Website
              if (
                response.data[wallet]?.properties?.verified?.website ||
                response.data[wallet]?.properties?.userDefined?.website
              ) {
                setWalletWebsite(
                  response.data[wallet]?.properties?.verified?.website ||
                    response.data[wallet]?.properties?.userDefined?.website
                );
              }
              //Domain
              if (
                response.data[wallet]?.properties?.verified?.domain ||
                response.data[wallet]?.properties?.userDefined?.domain
              ) {
                setWalletDomain(
                  response.data[wallet]?.properties?.verified?.domain ||
                    response.data[wallet]?.properties?.userDefined?.domain
                );
              }
              //Email
              if (response.data[wallet]?.properties?.verified?.email) {
                setWalletEmail(
                  response.data[wallet]?.properties?.verified?.email
                );
              }

              if (response.data[wallet].name) {
                //setHasNFD(true);
                setWalletName(response.data[wallet].name);
              } else {
                setWalletName(shortenAddress(wallet));
              }
              if (response.data[wallet]?.properties?.userDefined?.avatar) {
                if (response.data[wallet].properties.userDefined.avatar);
                setWalletAvatar(
                  response.data[wallet].properties.userDefined.avatar
                );
              } else {
                if (response.data[wallet]?.properties?.verified?.avatar) {
                  setWalletAvatar(
                    response.data[wallet].properties.verified.avatar.replace(
                      "ipfs://",
                      "https://images.nf.domains/ipfs/"
                    )
                  );
                }
              }
              if (response.data[wallet]?.properties?.verified?.banner) {
                setWalletBanner(
                  response.data[wallet].properties.verified.banner.replace(
                    "ipfs://",
                    "https://images.nf.domains/ipfs/"
                  )
                );
              }
              if (response.data[wallet]?.properties?.userDefined?.banner) {
                setWalletBanner(
                  response.data[wallet].properties.userDefined.banner.replace(
                    "ipfs://",
                    "https://images.nf.domains/ipfs/"
                  )
                );
              }
              if (response.data[wallet]?.properties?.userDefined?.bio) {
                setWalletBio(response.data[wallet].properties.userDefined.bio);
              }
            } else {
              setWalletName(shortenAddress(wallet));
              setWalletAvatar(pfp);
              setWalletBanner(banner);
              setWalletBio(false);
            }
          }
        })
        .catch((err) => {
          if (err) {
            //console.log(err);
            //console.clear();
            setWalletName(shortenAddress(wallet));
            setWalletAvatar(pfp);
            setWalletBanner(banner);
            setWalletBio(false);
          }
          return err;
        });
    }
  });
  useEffect(() => {
    setCheckedNFD(0);
  }, [wallet]);
  useEffect(() => {
    if (checkedNFD === 0) {
      fetchNFD();
    }
    setLoading(false);
  }, [wallet, fetchNFD, checkedNFD]);

  function shortenAddress(address, length = 5) {
    if (address) {
      return (
        address.substring(0, length) +
        "..." +
        address.substring(address.length - length)
      );
    } else {
      return "Unknown";
    }
  }
  return (
    <>
      {big === true && walletBanner ? (
        <>
          <div className="col-12">
            <div className="position-relative body-bg rounded-4 profile_img">
              <img
                src={walletBanner}
                className="img-fluid rounded-4"
                alt={walletName}
              />
              <div className="position-absolute top-100 start-0 translate-middle-y p-2 pfp_img bg-thumb ms-2">
                {walletName.includes(".algo") && activeAddress === wallet ? (
                  <Link
                    to={`https://app.nf.domains/manage/${wallet}?view=metadata`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="edit-pfp pfp_img"
                  >
                    <img
                      src={walletAvatar}
                      className="img-fluid pfp_img"
                      alt="PFP"
                      width="80"
                    />
                    <i className="icon fa-kit fa-regular-image-pen fa-xl fa-fw"></i>
                  </Link>
                ) : (
                  <img
                    src={walletAvatar}
                    className="img-fluid pfp_img"
                    alt="PFP"
                    width="80"
                  />
                )}
              </div>
              <div className="position-absolute top-100 end-0 translate-middle-y p-2 me-2 bg-thumb rounded-pill">
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-success btn-sm rounded-pill px-3"
                    data-bs-toggle="modal"
                    data-bs-target="#giftModal"
                  >
                    <i className="fa-solid fa-hand-holding-heart fa-lg me-2"></i>
                    Donate
                  </button>
                </div>
              </div>
            </div>
            <div className="row align-items-center pt-5 mt-3">
              <div className="col text-body-emphasis placeholder-glow">
                <strong className="h5">
                  {loading ? (
                    <span className="placeholder col-8"></span>
                  ) : (
                    <>
                      {walletName}{" "}
                      {walletName.includes("soapblox.algo") ||
                      walletName.includes("slurp.algo") ? (
                        <span className="c-tooltip ms-1">
                          <i className="fa-sharp fa-solid fa-badge-check text-warning ms-1"></i>
                          <span className="tooltiptext">Staff</span>
                        </span>
                      ) : (
                        walletVerified && (
                          <span className="c-tooltip ms-1">
                            <i className="fa-sharp fa-solid fa-badge-check text-info ms-1"></i>
                            <span className="tooltiptext">Verified</span>
                          </span>
                        )
                      )}
                    </>
                  )}
                  <br />
                  {loading ? (
                    <span className="placeholder col-10"></span>
                  ) : (
                    <small className="text-body-tertiary tiny">
                      <i className="fa-solid fa-wallet fa-fw"></i> {wallet}
                    </small>
                  )}
                </strong>
              </div>
            </div>
            {loading ? (
              <span className="placeholder col-8"></span>
            ) : (
              <>
                {walletWebsite &&
                  (walletDomain ? (
                    <a
                      href={walletWebsite}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-link btn-sm px-0"
                    >
                      <i className="fa-regular fa-link fa-fw"></i>{" "}
                      {walletDomain}
                    </a>
                  ) : (
                    <a
                      href={walletWebsite}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-link btn-sm px-0"
                    >
                      <i className="fa-regular fa-link fa-fw"></i>{" "}
                      {walletWebsite}
                    </a>
                  ))}
                {walletBio ? (
                  <div className="my-4 text-body-emphasis">{walletBio}</div>
                ) : (
                  <div className="mb-4"></div>
                )}
                {walletTwitter && (
                  <a
                    href={`https://x.com/${walletTwitter}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-outline-secondary rounded-pill px-3 me-2"
                  >
                    <i className="fa-brands fa-x-twitter fa-fw fa-lg"></i>
                  </a>
                )}
                {walletDiscord && (
                  <a
                    href={`https://discordapp.com/users/${walletDiscord}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-outline-secondary rounded-pill px-3 me-2"
                  >
                    <i className="fa-brands fa-discord fa-fw fa-lg"></i>
                  </a>
                )}
                {walletTelegram && (
                  <a
                    href={`https://t.me/${walletTelegram}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-outline-secondary rounded-pill px-3 me-2"
                  >
                    <i className="fa-brands fa-telegram fa-fw fa-lg"></i>
                  </a>
                )}
                {walletLinkedIn && (
                  <a
                    href={`https://linkedin.com/${walletLinkedIn}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-outline-secondary rounded-pill px-3 me-2"
                  >
                    <i className="fa-brands fa-linkedin-in fa-fw fa-lg"></i>
                  </a>
                )}
                {walletGitHub && (
                  <a
                    href={`https://github.com/${walletGitHub}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-outline-secondary rounded-pill px-3 me-2"
                  >
                    <i className="fa-brands fa-github fa-fw fa-lg"></i>
                  </a>
                )}
                {walletEmail && (
                  <a
                    href={`mailto:${walletEmail}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-outline-secondary rounded-pill px-3 me-2"
                  >
                    <i className="fa-light fa-envelope fa-fw fa-lg"></i>
                  </a>
                )}
              </>
            )}
          </div>
          <GiftModal to={wallet} name={walletName} />
        </>
      ) : tiny ? (
        <>
          <div className="col-auto placeholder-glow pfp-thumb">
            <img
              src={walletAvatar}
              className="img-fluid pfp_img profile-thumb cursor-pointer"
              alt="PFP"
              width="20"
              onClick={handleProfileOnClick}
            />
          </div>
          <div className="col text-body-emphasis placeholder-glow text-break small">
            <strong onClick={handleProfileOnClick} className="cursor-pointer">
              {loading ? (
                <span className="placeholder col-8"></span>
              ) : (
                walletName
              )}
              {walletName.includes("soapblox.algo") ||
              walletName.includes("slurp.algo") ? (
                <span className="c-tooltip ms-1">
                  <i className="fa-sharp fa-solid fa-badge-check text-warning ms-1"></i>
                  <span className="tooltiptext">Staff</span>
                </span>
              ) : (
                walletVerified && (
                  <span className="c-tooltip ms-1">
                    <i className="fa-sharp fa-solid fa-badge-check text-info ms-1"></i>
                    <span className="tooltiptext">Verified</span>
                  </span>
                )
              )}
              {timesince && (
                <small className="text-muted ms-2">{timesince}</small>
              )}
              {personal &&
                (loading ? (
                  <span className="placeholder col-10 d-block"></span>
                ) : (
                  <small className="text-body-tertiary  d-block">
                    {shortenAddress(wallet)}
                  </small>
                ))}
            </strong>
          </div>
        </>
      ) : (
        <>
          <div className="col-auto placeholder-glow pfp-thumb">
            <img
              src={walletAvatar}
              className="img-fluid pfp_img profile-thumb cursor-pointer"
              alt="PFP"
              width="60"
              onClick={handleProfileOnClick}
            />
          </div>
          <div className="col text-body-emphasis placeholder-glow text-break">
            <strong onClick={handleProfileOnClick} className="cursor-pointer">
              {loading ? (
                <span className="placeholder col-8"></span>
              ) : (
                walletName
              )}
              {walletName.includes("soapblox.algo") ||
              walletName.includes("slurp.algo") ? (
                <span className="c-tooltip ms-1">
                  <i className="fa-sharp fa-solid fa-badge-check text-warning ms-1"></i>
                  <span className="tooltiptext">Staff</span>
                </span>
              ) : (
                walletVerified && (
                  <span className="c-tooltip ms-1">
                    <i className="fa-sharp fa-solid fa-badge-check text-info ms-1"></i>
                    <span className="tooltiptext">Verified</span>
                  </span>
                )
              )}
              {timesince && (
                <small className="text-muted ms-2">{timesince}</small>
              )}
              {personal &&
                (loading ? (
                  <span className="placeholder col-10 d-block"></span>
                ) : (
                  <small className="text-body-tertiary  d-block">
                    {shortenAddress(wallet)}
                  </small>
                ))}
            </strong>
          </div>
        </>
      )}
    </>
  );
};
export default WalletBar;
