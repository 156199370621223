import React, { useEffect, useState } from "react";
import {
  SOAPBLOX_FEE,
  SOAPBLOX_WALLET,
  SOAPBLOX_PER_PAGE,
} from "Root/config.js";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Card from "../../components/card/card";
const Topic = (accountAddress) => {
  const [Data, setData] = useState();
  const [Next, setNext] = useState();
  const { topic } = useParams();
  const navigate = useNavigate();
  const handleOnClick = () => navigate("/");
  const fetchJson = (more) => {
    if (!more) {
      axios
        .get(
          "https://mainnet-idx.algonode.cloud/v2/transactions?tx-type=pay" +
            `&address=${SOAPBLOX_WALLET}` +
            "&address-role=receiver" +
            `&currency-greater-than=${SOAPBLOX_FEE - 1}` +
            `&currency-less-than=${SOAPBLOX_FEE + 1}` +
            "&note-prefix=" +
            btoa("soapblox:topic:" + btoa(topic)) +
            "&limit=" +
            SOAPBLOX_PER_PAGE
        )
        .catch((err) => {
          //console.log(err);
        })
        .then((response) => {
          if (response.data.transactions.length > 0) {
            //console.log(response.data);
            const parsedData = {
              entries: response.data.transactions,
            };
            if (parsedData) {
              setData(parsedData);
              setNext(response.data["next-token"]);
            }
          }
        });
    } else {
      axios
        .get(
          "https://mainnet-idx.algonode.cloud/v2/transactions?tx-type=pay" +
            `&address=${SOAPBLOX_WALLET}` +
            "&address-role=receiver" +
            `&currency-greater-than=${SOAPBLOX_FEE - 1}` +
            `&currency-less-than=${SOAPBLOX_FEE + 1}` +
            "&note-prefix=" +
            btoa("soapblox:topic:" + topic) +
            "&limit=" +
            SOAPBLOX_PER_PAGE +
            `&next=${more}`
        )
        .catch((err) => {
          //console.log(err);
        })
        .then((response) => {
          if (response.data.transactions.length > 0) {
            const parsedData = {
              entries: Data.entries.concat(response.data.transactions),
            };
            if (parsedData) {
              setData(parsedData);
              setNext(response.data["next-token"]);
            }
          }
        });
    }
  };
  useEffect(() => {
    fetchJson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic]);

  return (
    <>
      <div className="py-2 py-lg-5">
        <div id="blox" className="border rounded-4 overflow-hidden">
          <div className="p-3 p-lg-4  d-flex justify-content-between">
            <h4 className="text-body-emphasis m-0">
              Topic: #
              {
                <span
                  dangerouslySetInnerHTML={{
                    __html: topic,
                  }}
                ></span>
              }{" "}
            </h4>
            <button
              onClick={handleOnClick}
              className="btn btn-secondary btn-sm rounded-pill px-4"
            >
              <i className="fa-solid fa-circle-left"></i> Back
            </button>
          </div>
          {!Data && (
            <div className="p-3 p-lg-4 border-top">
              Nothing to see here, move along...
            </div>
          )}
          {Data?.entries.map((item) => (
            <Card
              key={item.id}
              id={item.id}
              wallet={item.sender}
              message={item.note}
              date={item["round-time"]}
              accountAddress={accountAddress}
            />
          ))}
          <div className="p-3 p-lg-4 border-top text-center">
            {/*Next !== "c7DeAQAAAAAFAAAA" ? (*/}
            {Data?.entries.length > SOAPBLOX_PER_PAGE - 1 ? (
              <button
                type="button"
                className="btn btn-secondary rounded-pill px-4"
                onClick={() => fetchJson(Next)}
              >
                Load More...
              </button>
            ) : (
              <>Looks like you're all caught up...</>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Topic;
