import { React, useState } from "react";
import { SOAPBLOX_FEE } from "Root/config.js";
import { Link } from "react-router-dom";
import axios from "axios";
const Faq = (accountAddress) => {
  const [algorandPrice, setAlgorandPrice] = useState([]);
  axios
    .get(
      "https://api.coingecko.com/api/v3/simple/price?ids=algorand&vs_currencies=usd"
    )
    .then((response) => {
      setAlgorandPrice(response.data.algorand.usd.toFixed(2));
    })
    .catch((err) => {
      if (!err) {
        setAlgorandPrice("0.09");
      }
    });
  return (
    <>
      <div className="py-2 py-lg-5">
        <div id="blox" className="border rounded-4 overflow-hidden">
          <div className="p-3 p-lg-4">
            <h4 className="text-body-emphasis m-0">FAQ</h4>
          </div>
          <div className="p-3 p-lg-4 border-top">
            I'll add to these over time, but seeing as this is a new platform,
            there aren't any frequently asked questions yet.
          </div>
          <div
            className="accordion accordion-flush border-top"
            id="faqAccordion"
          >
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq7"
                  aria-expanded="false"
                  aria-controls="faq7"
                >
                  How do I get started?
                </button>
              </h2>
              <div
                id="faq7"
                className="accordion-collapse collapse"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body p-5 p-md-5">
                  <p>
                    Please note that this isn't the only way, but it is probably
                    the easiest way to get started.
                  </p>
                  <p className="text-body-emphasis lead pt-4 mt-4 border-top">
                    Step 1:
                  </p>
                  <p>
                    Go to PeraWallet and get yourself an Algorand wallet{" "}
                    <Link to={"https://perawallet.app/"} target="_blank">
                      here
                    </Link>
                    .
                  </p>
                  <p className="text-body-emphasis lead pt-4 mt-4 border-top">
                    Step 2:
                  </p>
                  <p>
                    Write down your 25 word phrase and{" "}
                    <strong className="text-body-emphasis">KEEP IT SAFE</strong>
                    , this is you key to your wallet.{" "}
                    <strong className="text-warning">DO NOT LOSE IT</strong>. If
                    you lose it you'll lose whatever funds are in the wallet,
                    forever. There is no support centre you can call to get
                    access again.
                  </p>
                  <p className="text-body-emphasis lead pt-4 mt-4 border-top">
                    Step 3:
                  </p>
                  <p>
                    Click Add funds and buy as much $ALGO as you think is
                    required. 1 $ALGO gets your roughtly{" "}
                    {(1000000 / SOAPBLOX_FEE).toFixed(0)} posts.
                  </p>
                  <p className="text-body-emphasis">
                    1 $ALGO is roughly ${algorandPrice}
                    <sup className="text-danger">*</sup>
                  </p>
                  <p className="text-body-emphasis">
                    <small className="text-body text-opacity-75">
                      <sup className="text-danger">*</sup> If the coingecko API
                      retrieving the $ALGO price fails, this info will be
                      incorrect.
                    </small>
                  </p>
                  <p className="text-body-emphasis lead pt-4 mt-4 border-top">
                    Step 4:
                  </p>
                  <p>
                    Head to{" "}
                    <Link to={"https://soapblox.app/"} target="_blank">
                      soapblox.app
                    </Link>
                    , click connect and follow the instructions on screen.
                  </p>
                  <p className="text-body-emphasis lead pt-4 mt-4 border-top">
                    Step 5:
                  </p>
                  <p>
                    Post something that you don't mind being visible{" "}
                    <strong className="text-body-emphasis">FOREVER</strong>, and
                    follow the instructions after clicking Publish or Reply
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq1"
                  aria-expanded="false"
                  aria-controls="faq1"
                >
                  How do I sign up?
                </button>
              </h2>
              <div
                id="faq1"
                className="accordion-collapse collapse"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body p-5 p-md-5">
                  No need to sign up, just connect your wallet and you're good
                  to go.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq2"
                  aria-expanded="false"
                  aria-controls="faq2"
                >
                  What does it cost to post?
                </button>
              </h2>
              <div
                id="faq2"
                className="accordion-collapse collapse"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body p-5 p-md-5">
                  <p>
                    Posting and replying on the platform comes with a nominal
                    cost to ensure a sustainable ecosystem. Each post or reply
                    requires a fee of{" "}
                    <span className="text-success">
                      {SOAPBLOX_FEE / 1000000} $ALGO
                    </span>
                    , which covers my operational costs and maintenance.
                    Additionally, there's a standard Algorand transaction fee of
                    0.001 $ALGO associated with each interaction to facilitate
                    the blockchain transaction.
                  </p>

                  <p>
                    However, I've designed the system to encourage content
                    circulation. Therefore, reposting is essentially 'free' in
                    terms of the direct post fee, although the standard Algorand
                    transaction fee of 0.001 $ALGO still applies. This allows
                    you to share and reshare content without incurring the
                    initial posting cost.
                  </p>

                  <p>
                    Please note that these fee structures ensure the stability
                    and integrity of my platform while maintaining a balance
                    between your engagement and my operational sustainability.
                  </p>
                  <p className="text-success">
                    Estimated cost per post $
                    {algorandPrice * (SOAPBLOX_FEE / 1000000 + 0.001)}
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq3"
                  aria-expanded="false"
                  aria-controls="faq3"
                >
                  Why not have zero fees?
                </button>
              </h2>
              <div
                id="faq3"
                className="accordion-collapse collapse"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body p-5 p-md-5">
                  <p className="text-break">
                    The{" "}
                    <span className="text-body-emphasis">
                      {SOAPBLOX_FEE / 1000000} $ALGO
                    </span>{" "}
                    is sent to{" "}
                    <span className="text-body-emphasis">soapblox.algo</span>
                  </p>
                  <p>
                    I didn't want to clutter up the site with ads; personally, I
                    find them a bit intrusive and not so easy on the eyes. So, I
                    thought it would be a smarter move to have a small fee
                    attached to each post. It's designed to be subtle &minus;
                    nothing that would really grab your attention.
                  </p>
                  <p>
                    Honestly, without a decent influx of folks using the
                    platform, I'll be footing the bill for keeping the site up
                    and running for quite a while, or for as long as I can
                    manage.
                  </p>
                  <p>
                    Of course, I need to keep it real &minus; if the server and
                    API costs start piling up and put me in a tight spot, I
                    might have to consider exploring the ad revenue route.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq4"
                  aria-expanded="false"
                  aria-controls="faq4"
                >
                  Can I edit/ delete my posts?
                </button>
              </h2>
              <div
                id="faq4"
                className="accordion-collapse collapse"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body p-5 p-md-5">
                  <p>
                    No... For better or worse, what you post is{" "}
                    <em className="text-body-emphasis">forever</em>
                    <sup className="text-danger">*</sup>
                  </p>
                  <small className="text-body text-opacity-75">
                    <sup className="text-danger">*</sup> As long as the Algorand
                    Blockchain stands with it's current functionality.
                  </small>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq5"
                  aria-expanded="false"
                  aria-controls="faq5"
                >
                  Why only allow one topic?
                </button>
              </h2>
              <div
                id="faq5"
                className="accordion-collapse collapse"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body p-5 p-md-5">
                  <p>
                    Reduced spam, improved discoverability of information
                    related to specific topics. Or at least, that's the idea...
                    🤷
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq8"
                  aria-expanded="false"
                  aria-controls="faq8"
                >
                  Profit sharing?
                </button>
              </h2>
              <div
                id="faq8"
                className="accordion-collapse collapse"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body p-5 p-md-5">
                  <p>
                    At SoapBlox, we believe in sharing success. That's why we
                    split the fees collected for replies evenly, with 50% going
                    to the original poster and 50% to SoapBlox. It's our way of
                    implementing 'profit sharing'.
                  </p>
                  <p>
                    For each reply your posts receive, you'll earn{" "}
                    <span className="text-body-emphasis">
                      {SOAPBLOX_FEE / 2 / 1000000} $ALGO.
                    </span>
                  </p>
                  <p>
                    While this may seem modest, it's due to the minimal fees we
                    charge. We're excited to share our growth with our community
                    in this way.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq6"
                  aria-expanded="false"
                  aria-controls="faq6"
                >
                  If $ALGO soars, will the fees remain unchanged? 🚀
                </button>
              </h2>
              <div
                id="faq6"
                className="accordion-collapse collapse"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body p-5 p-md-5">
                  <p>
                    Nah, I really want to keep posts under $0.01 per post. So if
                    it ever comes to that, I'll lower the fees.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#faqVerified"
                  aria-expanded="false"
                  aria-controls="faq6"
                >
                  How do I get verified?{" "}
                  <i className="fa-sharp fa-solid fa-badge-check text-info ms-1"></i>
                </button>
              </h2>
              <div
                id="faqVerified"
                className="accordion-collapse collapse"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-body p-5 p-md-5">
                  <p>
                    You must possess an NFD (
                    <a
                      href="https://app.nf.domains/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://app.nf.domains/
                    </a>
                    ) and verify at least one of the following: your Twitter,
                    Discord, GitHub, domain, or email.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
