import React from "react";
import { useNavigate, Link } from "react-router-dom";
//import pfp from "../../nopfp.png";
import CardBar from "../cardbar/cardbar";
import WalletBar from "../../components/walletbar/walletbar";
import AutoLink from "../../helpers/autolink";
import AutoPreview from "../../helpers/autopreview";
import DOMPurify from "dompurify";

const Card = ({ id, wallet, message, date, accountAddress }) => {
  //const [walletAvatar, setWalletAvatar] = useState(pfp);
  //const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  //const [loading, setLoading] = useState(true);
  //const [topics, setTopics] = useState(false);
  const handleViewOnClick = () => navigate(`/view/${id}`);
  function base64Decode(input) {
    return atob(input);
  }
  function timeSince(ts) {
    var d = new Date();
    var nowTs = Math.floor(d.getTime() / 1000);
    var seconds = nowTs - ts;
    //Days
    if (seconds > 24 * 3600) {
      return Math.floor(seconds / 60 / 60 / 24) + "d";
    }
    //Hours
    if (seconds > 3600) {
      return Math.floor(seconds / 60 / 60) + "h";
    }
    //Minutes
    if (seconds > 60) {
      return Math.floor(seconds / 60) + "m";
    }
    if (seconds < 60) {
      return "Just now";
    }
  }
  const getData = (data) => {
    const pairs = data.split(";");
    const keyValueObject = {};

    for (const pair of pairs) {
      const [key, value] = pair.split(":");
      if (key && value) {
        keyValueObject[key] = value;
      }
    }
    return keyValueObject;
  };
  const dataTemp = base64Decode(message);
  const messageVar = base64Decode(
    getData(dataTemp.replace("soapblox:", "")).msg
  );
  const topicVar =
    getData(dataTemp.replace("soapblox:", ""))?.topic &&
    base64Decode(getData(dataTemp.replace("soapblox:", ""))?.topic);
  return (
    <div className="border-top p-3 p-lg-4" id={id}>
      <div className="row g-3">
        <div className="col">
          <div className="row align-items-center g-2 g-lg-3">
            <WalletBar
              wallet={wallet}
              big={false}
              personal={false}
              timesince={timeSince(date)}
            />
          </div>
          <div className="offset-thumb">
            <AutoLink
              text={DOMPurify.sanitize(
                messageVar.replace(/(?:\r\n|\r|\n)/g, " <br>"),
                {
                  ALLOWED_TAGS: ["b", "br"],
                }
              )}
            />
            <AutoPreview text={messageVar} />
            {topicVar && (
              <div className="d-block pt-2">
                <Link
                  to={"/topic/" + encodeURIComponent(topicVar)}
                  className="btn btn-link btn-sm rounded-2 px-0"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: topicVar,
                    }}
                  ></span>
                </Link>
              </div>
            )}
            <div className="d-flex align-items-center justify-content-between pt-4">
              <span onClick={handleViewOnClick}>
                <CardBar txid={id} />
              </span>
              <span>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm px-4 rounded-pill"
                  onClick={handleViewOnClick}
                >
                  Expand
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Card;
