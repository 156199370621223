import { useEffect, useState } from "react";
import { SOAPBLOX_DISCORD, SOAPBLOX_TWITTER } from "Root/config.js";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import "./App.scss";

import logo from "./logo.png";
import "bootstrap";
import algoLogo from "./algorand_full_logo_white.png";
import nfd from "./nfd.png";
import sbLogo from "./sb-logo.png";
// import shufl from "./shufl.png";
// import nfdmobile from "./nfdmobile.jpg";

import WalletBar from "./components/walletbar/walletbar";
import Home from "./sections/home/home";
import View from "./sections/view/view";
import Topic from "./sections/topic/topic";
import Profile from "./sections/profile/profile";
import About from "./sections/about/about";
import Roadmap from "./sections/roadmap/roadmap";
import Faq from "./sections/faq/faq";
import Tos from "./sections/tos/tos";
import Trending from "./components/trending/trending";
import LatestNFTS from "./components/latestnfts/latestnfts";
import ConnectMenu from "./components/connectmenu/connectmenu";
import ShareModal from "./components/modals/share/share";
import ScrollToTop from "../src/helpers/scrolltotop";

import {
  WalletProvider,
  useInitializeProviders,
  PROVIDER_ID,
  useWallet,
} from "@txnlab/use-wallet";
import { DeflyWalletConnect } from "@blockshake/defly-connect";
import { PeraWalletConnect } from "@perawallet/connect";
import { DaffiWalletConnect } from "@daffiwallet/connect";

export default function App() {
  const [inputValue, setInputValue] = useState("");

  const location = useLocation();
  const account = useWallet();

  const accountAddress = account.activeAddress;
  const providers = useInitializeProviders({
    providers: [
      { id: PROVIDER_ID.DEFLY, clientStatic: DeflyWalletConnect },
      { id: PROVIDER_ID.PERA, clientStatic: PeraWalletConnect },
      { id: PROVIDER_ID.DAFFI, clientStatic: DaffiWalletConnect },
    ],
  });
  useEffect(() => {
    const shareModal = document.getElementById("shareModal");
    const shareInput = document.getElementById("speak");

    shareModal?.addEventListener("shown.bs.modal", () => {
      shareInput.focus();
    });
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const adjustedInput = inputValue.endsWith(".algo")
      ? inputValue
      : `${inputValue}.algo`;
    window.location.href = `/profile/${adjustedInput}`;
  };

  return (
    <WalletProvider value={providers}>
      <div
        className="offcanvas offcanvas-start col-12 col-lg-3"
        tabIndex="-1"
        id="offcanvasMenu"
        aria-labelledby="offcanvasMenuLabel"
      >
        <div className="offcanvas-header">
          <h5
            className="offcanvas-title fw-light text-brand"
            id="offcanvasMenuLabel"
          >
            {" "}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <Link
            className={`btn btn-outline-secondary rounded-pill d-block w-100 mb-3 ${
              location.pathname === "/" && "active"
            }`}
            to="/"
            reloadDocument
          >
            Feed
          </Link>
          <Link
            className={`btn btn-outline-secondary rounded-pill d-block w-100 mb-3 ${
              location.pathname === "/about" && "active"
            }`}
            to="about"
            reloadDocument
          >
            About
          </Link>
          <Link
            className={`btn btn-outline-secondary rounded-pill d-block w-100 mb-3 ${
              location.pathname === "/roadmap" && "active"
            }`}
            to="roadmap"
            reloadDocument
          >
            Roadmap
          </Link>
          <Link
            className={`btn btn-outline-secondary rounded-pill d-block w-100 mb-3 ${
              location.pathname === "/faq" && "active"
            }`}
            to="faq"
            reloadDocument
          >
            FAQ
          </Link>
          <Link
            className={`btn btn-outline-secondary rounded-pill d-block w-100 ${
              location.pathname === "/terms" && "active"
            }`}
            to="terms"
            reloadDocument
          >
            Terms
          </Link>
        </div>
      </div>
      <div className="container-fluid g-4 px-lg-6">
        <div className="row g-4 g-md-5 row-eq-height h-100">
          <div className="col-12 col-lg-4 col-xl-3 text-body-emphasis">
            <div className="sticky-top vh-100 py-4 py-lg-5 d-flex  flex-column overflow-auto mod-scrollbar">
              <div>
                <div className="d-flex justify-content-between mt-5 mt-lg-0">
                  <Link to="/" className="position-relative">
                    <img
                      src={logo}
                      className="img-fluid mx-auto mx-lg-0"
                      alt="SoapBlox"
                      width="180"
                    />
                    <span className="beta">BETA</span>
                  </Link>
                  <button
                    className="btn btn-primary rounded-nav position-fixed top-0 end-0 d-lg-none"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasMenu"
                  >
                    <i className="fa-regular fa-bars fa-xl"></i>
                  </button>
                </div>
                <p className="text-body-emphasis mt-4 lead d-none d-lg-block">
                  Decentralized social media, powered by the Algorand
                  blockchain.
                </p>
              </div>
              <div className="mt-auto d-none d-lg-block text-center">
                <Link
                  className={`btn btn-outline-secondary rounded-pill d-block w-100 mb-0 mb-lg-4 ${
                    location.pathname === "/" && "active"
                  }`}
                  to="/"
                >
                  Feed
                </Link>
                <Link
                  className={`btn btn-outline-secondary rounded-pill d-block w-100 mb-0 mb-lg-4 ${
                    location.pathname === "/roadmap" && "active"
                  }`}
                  to="roadmap"
                >
                  Roadmap
                </Link>
                <Link
                  className={`btn btn-link rounded-pill mb-0 mb-lg-4 ${
                    location.pathname === "/about" && "active"
                  }`}
                  to="about"
                >
                  About
                </Link>
                <Link
                  className={`btn btn-link rounded-pill mb-0 mb-lg-4 ${
                    location.pathname === "/faq" && "active"
                  }`}
                  to="faq"
                >
                  FAQ
                </Link>
                <Link
                  className={`btn btn-link rounded-pill mb-0 mb-lg-4 ${
                    location.pathname === "/terms" && "active"
                  }`}
                  to="terms"
                >
                  Terms
                </Link>
                <br />
                <Link
                  className="btn btn-link mb-0 mb-lg-4"
                  to={SOAPBLOX_TWITTER}
                  target="_blank"
                >
                  <i className="fa-brands fa-x-twitter"></i>
                </Link>
                <Link
                  className="btn btn-link mb-0 mb-lg-4"
                  to={SOAPBLOX_DISCORD}
                  target="_blank"
                >
                  <i className="fa-brands fa-discord"></i>
                </Link>
              </div>
              <div className="row g-3 mt-auto w-100 align-items-center share-base">
                <div className="col-auto col-lg-12 order-5 order-lg-0">
                  {accountAddress && (
                    <>
                      <button
                        className="btn btn-primary rounded-pill d-block w-100 mb-0 mb-lg-4 position-share"
                        data-bs-toggle="modal"
                        data-bs-target="#shareModal"
                      >
                        Share
                      </button>
                    </>
                  )}
                  {!accountAddress && (
                    <button
                      className="btn btn-primary rounded-pill d-block w-100 mb-0 mb-lg-4"
                      data-bs-toggle="modal"
                      data-bs-target="#connectModal"
                    >
                      Connect
                    </button>
                  )}
                </div>
                {accountAddress && (
                  <>
                    <WalletBar
                      wallet={accountAddress}
                      big={false}
                      personal={true}
                    />
                    <div className="col-auto text-end">
                      <button
                        className="btn btn-link border px-2"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#connectModal"
                      >
                        <i className="fa-solid fa-ellipsis fa-fw"></i>
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 col-xl-6">
            <Routes>
              <Route
                exact
                path="/"
                element={<Home accountAddress={accountAddress} />}
              />
              <Route
                exact
                path="/about"
                element={<About accountAddress={accountAddress} />}
              />
              <Route
                exact
                path="/roadmap"
                element={<Roadmap accountAddress={accountAddress} />}
              />
              <Route
                exact
                path="/faq"
                element={<Faq accountAddress={accountAddress} />}
              />
              <Route
                exact
                path="/terms"
                element={<Tos accountAddress={accountAddress} />}
              />
              <Route
                exact
                path="/view/:txid"
                element={<View accountAddress={accountAddress} />}
              />
              <Route
                exact
                path="/topic/:topic"
                element={<Topic accountAddress={accountAddress} />}
              />
              <Route
                exact
                path="/profile/:user"
                element={<Profile accountAddress={accountAddress} />}
              />
            </Routes>
            <div className="pb-5 text-body-emphasis text-center">
              "My Mom has 4 oranges."
              <small className="text-body text-opacity-75 d-block">
                - John Alan Woods
              </small>
            </div>
          </div>
          <div className="col-12 col-lg-12 col-xl">
            <div className="sticky-top vh-100 overflow-auto z-0 py-4 py-lg-5 d-flex flex-column mod-scrollbar">
              <div className="rounded-4 border mb-4">
                <div className="p-3">
                  <form onSubmit={handleSubmit}>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control rounded-start-3"
                        placeholder="Search username..."
                        aria-label="Search username..."
                        aria-describedby="button-search"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                      <button
                        className="btn btn-primary rounded-end-3"
                        type="submit"
                        id="button-search"
                      >
                        <i className="fa-regular fa-magnifying-glass fa-fw"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              {!location.pathname.includes("/profile/") && (
                <div className="rounded-4 border mb-4">
                  <div className="p-3 border-bottom">
                    <h4 className="text-body-emphasis m-0">Topics</h4>
                  </div>
                  <div className="p-3">
                    <Trending />
                  </div>
                </div>
              )}
              <LatestNFTS />
              {!location.pathname.includes("/profile/") && (
                <div className="row g-4 row-eq-height">
                  <div className="col-12 col-sm-6 col-xl-12">
                    <div className="card rounded-4 h-100">
                      <div className="row g-0 row-eq-height h-100">
                        <div className="col-4 bg-nfd position-relative rounded-start-4">
                          <Link
                            to={"https://app.nf.domains"}
                            className="rounded-start"
                            target="_blank"
                          >
                            <img
                              src={nfd}
                              className="img-fluid d-block mx-auto opacity-0"
                              width="220"
                              alt="..."
                            />
                            <img
                              src={nfd}
                              className="img-fluid d-block position-absolute start-50 top-50 translate-middle"
                              width="220"
                              alt="..."
                            />
                          </Link>
                        </div>
                        <div className="col-8">
                          <div className="card-body justify-content-between d-flex flex-column">
                            <p className="text-body-emphasis m-0">
                              Works better with NFDomains
                            </p>
                            <div>
                              <Link
                                to={"https://app.nf.domains"}
                                className="btn btn-outline-secondary btn-sm rounded-pill px-3 mt-3"
                                target="_blank"
                              >
                                Get your NFD
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-xl-12">
                    <div className="card rounded-4 h-100">
                      <div className="row g-0 row-eq-height h-100">
                        <div className="col-4 bg-sb position-relative rounded-start-4">
                          <Link to="/faq" className="rounded-start">
                            <img
                              src={sbLogo}
                              className="img-fluid d-block mx-auto opacity-0"
                              width="220"
                              alt="..."
                            />
                            <img
                              src={sbLogo}
                              className="img-fluid d-block position-absolute start-50 top-50 translate-middle"
                              width="220"
                              alt="..."
                            />
                          </Link>
                        </div>
                        <div className="col-8">
                          <div className="card-body justify-content-between d-flex flex-column">
                            <p className="text-body-emphasis m-0">
                              Get Rewarded! Profit sharing built in.
                            </p>
                            <div>
                              <Link
                                to="/faq"
                                className="btn btn-outline-secondary btn-sm rounded-pill px-3 mt-3"
                              >
                                Read FAQ
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="text-center mt-auto w-100 pt-5 pt-lg-0">
                <small>POWERED BY:</small>
                <img
                  src={algoLogo}
                  className="img-fluid d-block mx-auto"
                  width="100"
                  alt="Powered by Algorand"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="connectModal"
        tabIndex="-1"
        aria-labelledby="connectModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-3 p-lg-4">
              <button
                type="button"
                className="btn-close position-absolute top-0 end-0 me-3 mt-3"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <ConnectMenu />
            </div>
          </div>
        </div>
      </div>
      <ShareModal activeWallet={accountAddress} />
      <ScrollToTop />
    </WalletProvider>
  );
}
