import React from "react";
import { useWallet } from "@txnlab/use-wallet";

export default function ConnectMenu() {
  const { providers, activeAccount } = useWallet();

  // 1. Map over `providers` array
  // 2. Show the provider name/icon and "Connect", "Set Active", and "Disconnect" buttons
  // 3. If active, map `provider.accounts` to render a select menu of connected accounts

  return (
    <div>
      {providers?.map((provider) => (
        <div key={provider.metadata.id} className="border-bottom p-3">
          <div className="row g-3 align-items-end">
            <div className="col-auto">
              <img
                width={50}
                height={50}
                alt={`${provider.metadata.name} icon`}
                src={provider.metadata.icon}
                className="rounded-circle"
              />
            </div>
            <div className="col">
              <strong className="text-body-emphasis">
                {provider.metadata.name}
                {!provider.isActive && (
                  <>
                    <br />
                    <small className="text-muted">Wallet</small>
                  </>
                )}
              </strong>
              {provider.isActive && provider.accounts.length && (
                <select
                  className="form-select form-select-sm"
                  value={activeAccount?.address}
                  onChange={(e) => provider.setActiveAccount(e.target.value)}
                >
                  {provider.accounts.map((account) => (
                    <option key={account.address} value={account.address}>
                      {account.address}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="col-12 col-md-auto">
              <button
                type="button"
                className={`btn btn-dark btn-sm w-100 wallet-btn ${
                  !provider.isConnected && "d-block"
                }`}
                onClick={provider.connect}
                disabled={provider.isConnected}
              >
                <i className="fa-regular fa-square-plus fa-fw text-success"></i>{" "}
                Connect
              </button>
              <button
                type="button"
                className={`btn btn-dark btn-sm w-100 wallet-btn ${
                  provider.isConnected && !provider.isActive && "d-block"
                }`}
                onClick={provider.setActiveProvider}
                disabled={!provider.isConnected || provider.isActive}
              >
                <i className="fa-regular fa-square-check fa-fw text-info"></i>{" "}
                Set Active
              </button>
              <button
                type="button"
                className={`btn btn-dark btn-sm w-100 wallet-btn ${
                  provider.isConnected && provider.isActive && "d-block"
                }`}
                onClick={provider.disconnect}
                disabled={!provider.isConnected}
              >
                <i className="fa-regular fa-square-minus fa-fw text-danger"></i>
                Disconnect
              </button>
            </div>
          </div>
        </div>
      ))}
      <small className="d-block text-center mt-3">
        Where your virtual riches reside
      </small>
    </div>
  );
}
