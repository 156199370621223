import React from "react";
export default function Warning() {
  return (
    <div className="alert alert-warning mt-3">
      Whatever you say here is stored on the blockchain for all of time, no take
      backsies... What is said CAN NOT be unsaid. So triple check before
      signing.
    </div>
  );
}
