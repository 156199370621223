import React, { useEffect, useState } from "react";
import { SOAPBLOX_FEE, SOAPBLOX_WALLET } from "Root/config.js";
import axios from "axios";
import Topic from "../topic/topic";
const Trending = (accountAddress) => {
  const [Data, setData] = useState();
  const fetchJson = () => {
    axios
      .get(
        "https://mainnet-idx.algonode.cloud/v2/transactions?tx-type=pay" +
          `&address=${SOAPBLOX_WALLET}` +
          "&address-role=receiver" +
          `&currency-greater-than=${SOAPBLOX_FEE - 1}` +
          `&currency-less-than=${SOAPBLOX_FEE + 1}` +
          "&note-prefix=c29hcGJsb3g6dG9waWM6" +
          "&limit=300"
      )
      .catch((err) => {
        //console.log(err);
      })
      .then((response) => {
        if (response.data.transactions.length > 0) {
          //console.log(response.data);
          const parsedData = {
            entries: response.data.transactions,
          };
          if (parsedData) {
            // console.log(parsedData);
            setData(parsedData);
            // setTopics(Topics + response.data.transactions);
          }
        }
      });
  };
  useEffect(() => {
    fetchJson();
  }, []);

  return (
    <>
      {!Data ? (
        <small className="text-body text-opacity-75">
          <span className="h1">😐😴</span>
        </small>
      ) : (
        <Topic Data={Data?.entries} />
      )}
    </>
  );
};

export default Trending;
