import React from "react";
import WalletBar from "../../components/walletbar/walletbar";
const About = (accountAddress) => {
  return (
    <>
      <div className="py-2 py-lg-5">
        <div id="blox" className="border rounded-4 overflow-hidden">
          <div className="p-3 p-lg-4">
            <h4 className="text-body-emphasis m-0">Welcome to SoapBlox!</h4>
          </div>
          <div className="border-top p-3 p-lg-4">
            <p>
              Soapblox is an experimental decentralized social media platform,
              inspired by the classic soapbox, where users can freely post and
              respond, embracing total freedom of speech.
            </p>
            <p>
              The platform employs uncomplicated notes attached to Algorand
              transactions, enabling you to share thoughts, engage, and
              participate in communities with freedom.
            </p>
            <p className="text-body-emphasis">Join me on this adventure!</p>
            <div className="row g-3 mt-auto w-100 align-items-center">
              <WalletBar
                wallet="JYCFO55ZTKMYCYB3WC37EVG22Y2AUWUU6LK366T3NQTZZTEQYVLXMKHAJA"
                big={false}
                personal={false}
              />
            </div>
          </div>
        </div>
        <div className="border rounded-4 mt-4 d-none d-lg-block">
          <div className="p-3 p-lg-4">
            <h6 className="text-body-emphasis m-0">
              Pronunciation: <span className="fw-light">/'səʊp.blɒks/</span>
            </h6>
          </div>
          <div className="p-3 p-lg-4 border-top">
            <figure>
              <blockquote className="blockquote">
                <p className="text-body-emphasis small">Soapbox</p>
              </blockquote>
              <figcaption className="blockquote-footer">
                A soapbox is a raised platform on which one stands to make an
                impromptu speech.
              </figcaption>
            </figure>
            <figure>
              <blockquote className="blockquote">
                <p className="text-body-emphasis small">Blox</p>
              </blockquote>
              <figcaption className="blockquote-footer">
                As in the Blocks of a Blockchain.
              </figcaption>
            </figure>
            <p className="text-body-emphasis fst-italic mb-0">
              yes, it's a very clever name...
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
