import React, { useEffect, useState } from "react";
import {
  SOAPBLOX_FEE,
  SOAPBLOX_WALLET,
  SOAPBLOX_PER_PAGE,
} from "Root/config.js";
import axios from "axios";
import Card from "../../components/card/card";
import sbLogo from "../../sb-logo.png";
const Home = (accountAddress) => {
  const [Data, setData] = useState();
  const [Next, setNext] = useState();
  const [hasMore, setHasMore] = useState(true);
  function fetchJson(more) {
    const url = `https://mainnet-idx.algonode.cloud/v2/transactions?tx-type=pay&address=${SOAPBLOX_WALLET}&address-role=receiver&currency-greater-than=${
      SOAPBLOX_FEE - 1
    }&currency-less-than=${
      SOAPBLOX_FEE + 1
    }&note-prefix=c29hcGJsb3g6&limit=${SOAPBLOX_PER_PAGE}`;
    const paginatedUrl = more ? `${url}&next=${more}` : url;

    axios
      .get(paginatedUrl)
      .then((response) => {
        if (response.data.transactions.length > 0) {
          const newEntries = more
            ? Data.entries.concat(response.data.transactions)
            : response.data.transactions;
          setData({ entries: newEntries });
          setNext(response.data["next-token"]);
          if (newEntries.length % 20 !== 0) {
            setHasMore(false);
          }
        }
      })
      .catch((err) => {
        //console.error(err);
        // Handle the error appropriately
      });
  }

  useEffect(() => {
    fetchJson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="py-2 py-lg-5">
        <div className="bg-standard sticky-top z-1">
          <div className="rounded-top-4 border mb-n-1px p-3 p-lg-4 d-flex align-items-center justify-content-between">
            <h5 className="text-body-emphasis m-0">Feed</h5>
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={handleReload}
              >
                <i className="fa-light fa-arrows-rotate"></i>
              </button>
              <img
                src={sbLogo}
                width="40"
                className="d-block d-lg-none ms-3 opacity-25"
                alt="SoapBlox"
              />
            </div>
          </div>
        </div>
        <div
          id="blox"
          className="border border-top-0 rounded-top-0 rounded-4 overflow-hidden"
        >
          {!Data && (
            <div className="p-3 p-lg-4 border-top">
              Nothing to see here, move along...
            </div>
          )}
          {Data?.entries.map((item) => (
            <Card
              key={item.id}
              id={item.id}
              wallet={item.sender}
              message={item.note}
              date={item["round-time"]}
              accountAddress={accountAddress}
            />
          ))}
          <div className="p-3 p-lg-4 border-top text-center">
            {Data?.entries.length > SOAPBLOX_PER_PAGE - 1 &&
            hasMore === true ? (
              <button
                type="button"
                className="btn btn-secondary rounded-pill px-4"
                onClick={() => fetchJson(Next)}
              >
                Load More...
              </button>
            ) : (
              <>Looks like you're all caught up...</>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
