import React, { useState, useEffect } from "react";
import WalletBar from "../../walletbar/walletbar";
import ReplyTransaction from "../../../helpers/replyTransaction.tsx";
import Warning from "../../../helpers/warning.js";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import AutoLink from "../../../helpers/autolink";
import DOMPurify from "dompurify";
import { encode } from "html-entities";

const ReplyModal = ({ txid, activeWallet, messageVar, sender }) => {
  const [message, setMessage] = useState("");
  const [inputText, setInputText] = useState("");
  const [characterLimit] = useState(300);
  // event handler
  const handleChange = (event) => {
    setInputText(event.target.value);
  };

  useEffect(() => {
    const replyModal = document.getElementById("replyModal");
    const replyInput = document.getElementById("speakReply");

    replyModal.addEventListener("shown.bs.modal", () => {
      replyInput.focus();
    });
  }, []);

  return (
    <>
      <div
        className="modal fade"
        id="replyModal"
        tabIndex="-1"
        aria-labelledby="replyModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form id="replyform">
              <div className="modal-body p-3 p-lg-4">
                <button
                  type="button"
                  className="btn-close position-absolute top-0 end-0 me-3 mt-3"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div className="mb-4">
                  <label
                    forhtml="speakReply"
                    className="row align-items-center mb-4 g-3"
                  >
                    <WalletBar
                      wallet={activeWallet}
                      big={false}
                      personal={false}
                    />
                  </label>
                  <p>
                    <small className="text-body-emphasis">Replying to:</small>
                  </p>
                  <div className="border rounded-4 mb-4 p-3 bg-darker">
                    <div className="row g-2 align-items-center">
                      <WalletBar
                        wallet={sender}
                        big={false}
                        personal={false}
                        tiny={true}
                      />
                    </div>
                    <AutoLink
                      text={DOMPurify.sanitize(
                        messageVar.replace(/(?:\r\n|\r|\n)/g, " <br>"),
                        {
                          ALLOWED_TAGS: ["b", "br"],
                        }
                      )}
                    />
                  </div>

                  <div className="position-relative">
                    <div className="border pb-45 rounded-4 overflow-hidden text-editor">
                      <textarea
                        className="form-control rounded-0 border-0 border-bottom"
                        id="speakReply"
                        aria-describedby="speakReplyHelp"
                        rows="5"
                        maxLength="300"
                        placeholder="What's on your mind?"
                        value={message}
                        onChange={(e) =>
                          setMessage(e.target.value) + handleChange(e)
                        }
                        role="application"
                        autoFocus={true}
                      ></textarea>
                    </div>
                    <div className="position-absolute bottom-0 start-0 p-1">
                      <button
                        className="btn btn-sm btn-dark border-0 text-body-emphasis px-2 py-0 m-0"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#replyEmojiOffCanvas"
                        aria-controls="replyEmojiOffCanvas"
                      >
                        <i className="fa-sharp fa-regular fa-face-smile fa-fw"></i>
                      </button>
                      <div
                        className="offcanvas offcanvas-end"
                        tabIndex="-1"
                        id="replyEmojiOffCanvas"
                        aria-labelledby="replyEmojiOffCanvasLabel"
                      >
                        <div className="offcanvas-header">
                          <h5
                            className="offcanvas-title"
                            id="replyEmojiOffCanvasLabel"
                          >
                            Emojis
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="offcanvas-body">
                          <Picker
                            data={data}
                            perLine="8"
                            searchPosition="none"
                            previewPosition="none"
                            onEmojiSelect={(emoji) =>
                              setMessage(message + " " + emoji.native) +
                              document.getElementById("speakReply").focus()
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <span className="p-1 position-absolute bottom-0 end-0">
                      <span
                        className={`btn btn-sm cursor-none btn-dark border-0 text-body-emphasis px-2 py-0 m-0 ${
                          (inputText.length > characterLimit - 50) &
                          (inputText.length <= characterLimit)
                            ? "bg-warning"
                            : ""
                        } ${
                          inputText.length > characterLimit ? "bg-danger" : ""
                        }`}
                      >
                        {inputText.length}/{characterLimit}
                      </span>
                    </span>
                  </div>
                  <div id="speakReplyHelp">
                    <Warning />
                  </div>
                </div>
                <ReplyTransaction
                  note={
                    message &&
                    "soapbloxreply:" +
                      txid +
                      ";msg:" +
                      new Buffer.from(
                        encode(message, {
                          mode: "nonAsciiPrintableOnly",
                          level: "xml",
                        })
                      ).toString("base64")
                  }
                  OG={sender}
                  length={message.length}
                  reply={true}
                  txid={txid}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReplyModal;
