import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import algosdk from "algosdk";
import pfp from "Root/nopfp.png";
import { CID } from "multiformats/cid";
import { sha256 } from "multiformats/hashes/sha2";
import { create } from "multiformats/hashes/digest";
//import { useNavigate } from "react-router-dom";
import axios from "axios";
const Asset = ({ id }) => {
  const [assetInfo, setAssetInfo] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAssetInfo = async () => {
    await axios
      .get(`https://mainnet-idx.algonode.cloud/v2/assets/${id}`)
      .then((response) => {
        if (!assetInfo) {
          setAssetInfo(response.data.asset);
        }
      })
      .catch((err) => {});
  };
  const [ipfsImg, setIpfsImg] = useState(false);
  const [ipfsImgArc3, setIpfsImgArc3] = useState(false);
  function getAssetImage(asset) {
    const address = algosdk.decodeAddress(asset.params.reserve).publicKey;
    const hash = create(sha256.code, address);
    const codec = asset.params.url.includes(":dag-pb") ? 112 : 85;
    const version = asset.params.url.includes("ipfscid:0") ? 0 : 1;
    const cid = CID.create(version, codec, hash).toString();
    const ipfsLink = `https://ipfs.algonode.xyz/ipfs/${cid}`;
    //console.log(asset.params.url);
    if (
      asset.params.url.includes("ipfscid:0") ||
      asset.params.url.includes("ipfscid:1") ||
      asset.params.url.includes("ipfscid:0") ||
      asset.params.url.includes("ipfscid:1")
    ) {
      var imgLink = ipfsLink;
      axios.get(ipfsLink).then((response) => {
        if (response.status === 200) {
          if (response.headers["x-ipfs-root"]) {
            setIpfsImg(response.headers["x-ipfs-root"]);
          } else {
            if (response.data.image) {
              setIpfsImg(
                response.data.image
                  .replace("ipfs://", "https://ipfs.algonode.xyz/ipfs/")
                  .replace("infura.", "")
              );
            }
          }
        }
      });
      if (ipfsImg) {
        return ipfsImg;
      } else {
        return imgLink;
      }
    } else {
      if (asset.params.url.includes("#arc3")) {
        axios
          .get(
            asset.params.url.replace(
              "ipfs://",
              "https://ipfs.algonode.xyz/ipfs/"
            )
          )
          .then((response) => {
            if (response.data.image) {
              setIpfsImgArc3(
                response.data.image
                  .replace("ipfs://", "https://ipfs.algonode.xyz/ipfs/")
                  .replace("infura.", "")
              );
            }
          });
        if (ipfsImgArc3) {
          return ipfsImgArc3;
        } else {
          return pfp;
        }
      } else {
        return asset.params.url
          .replace("ipfs://", "https://ipfs.algonode.xyz/ipfs/")
          .replace("infura.", "");
      }
    }
  }
  useEffect(() => {
    getAssetInfo();
  });
  return (
    <>
      {assetInfo !== false &&
        assetInfo.params.name &&
        assetInfo.params.url &&
        assetInfo.params["unit-name"] !== "NFD" && (
          <>
            <div className="col-3 col-sm-3 col-lg-2 col-xl-5">
              <Link to={`https://www.asalytic.app/nft/` + assetInfo.index}>
                <img
                  src={getAssetImage(assetInfo) + "?optimizer=image&width=200"}
                  data-src={
                    getAssetImage(assetInfo) + "?optimizer=image&width=200"
                  }
                  className="img-fluid rounded-4"
                  alt={assetInfo.params.name}
                />
              </Link>
            </div>
            <div className="col-9 col-sm-3 col-lg-2 col-xl-7">
              <small className="d-block text-truncate text-body-emphasis">
                {assetInfo.params.name}
                <br />
                <small className="text-muted">{assetInfo.index}</small>
              </small>
              <Link
                to={`https://www.asalytic.app/nft/` + assetInfo.index}
                className="btn btn-outline-secondary btn-tiny rounded-pill d-block w-100 mt-3"
                target="_blank"
              >
                View
              </Link>
            </div>
          </>
        )}
    </>
  );
};
export default Asset;
