import React, { useState, useEffect } from "react";
import { SOAPBLOX_FEE, SOAPBLOX_WALLET } from "Root/config.js";
const CardBar = ({ txid }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchJson = () => {
    fetch(
      "https://mainnet-idx.algonode.cloud/v2/transactions?tx-type=pay" +
        `&address=${SOAPBLOX_WALLET}` +
        "&address-role=receiver" +
        `&note-prefix=` +
        btoa("soapbloxreply:" + txid + ";") +
        `&currency-greater-than=${SOAPBLOX_FEE / 2 - 1}` +
        `&currency-less-than=${SOAPBLOX_FEE / 2 + 1}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.transactions) {
          data.transactions.forEach((element) => {
            setCount(data.transactions.length);
          });
        }
      });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchJson();
    setLoading(false);
  });
  return (
    <button className="btn btn-link btn-sm">
      <i className="fa-sharp fa-regular fa-comment me-1"></i>{" "}
      {loading ? <span className="placeholder col-4"></span> : count}
    </button>
  );
};
export default CardBar;
