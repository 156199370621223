import React from "react";
import { SOAPBLOX_FEE, SOAPBLOX_WALLET } from "../config.js";
import algosdk from 'algosdk';

import {
  useWallet,
  DEFAULT_NODE_BASEURL,
  DEFAULT_NODE_TOKEN,
  DEFAULT_NODE_PORT
} from '@txnlab/use-wallet';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const algodClient = new algosdk.Algodv2(DEFAULT_NODE_TOKEN, DEFAULT_NODE_BASEURL, DEFAULT_NODE_PORT)
export default function Transact({ note, reply, OG, txid }) {
  const OG_WALLET = OG;
  //const [hasNote, setHasNote] = useState(true);
  //const navigate = useNavigate();
  const { activeAddress, signTransactions, sendTransactions } = useWallet()
  const sendTransaction = async () => {
    toast.info("Signing & Sharing!", {
      position: "bottom-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
    try {
      if (!activeAddress || !SOAPBLOX_WALLET || !SOAPBLOX_FEE) {
        throw new Error('Missing transaction params.')
      }

      const suggestedParams = await algodClient.getTransactionParams().do()

      const transaction1 = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        from: activeAddress,
        to: SOAPBLOX_WALLET,
        amount: SOAPBLOX_FEE/2,
        note: new Uint8Array(Buffer.from(note)),
        suggestedParams
      })
      const transaction2 = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        from: activeAddress,
        to: OG_WALLET,
        amount: SOAPBLOX_FEE/2,
        note: new Uint8Array(Buffer.from('Reply fee from SoapBlox')),
        suggestedParams
      })
      //const assigntxn = algosdk.assignGroupID([transaction1,transaction2])
      //const txn = algosdk.computeGroupID(assigntxn)
      const txnsToGroupTxn = [transaction1, transaction2];

      const groupID = algosdk.computeGroupID(txnsToGroupTxn);
      for (let i = 0; i < txnsToGroupTxn.length; i++) txnsToGroupTxn[i].group = groupID;
  
      const txnsToGroup = txnsToGroupTxn.map((txn) => algosdk.encodeUnsignedTransaction(txn));
      //const encodedTransaction1 = algosdk.encodeUnsignedTransaction(transaction1)
      //const encodedTransaction2 = algosdk.encodeUnsignedTransaction(transaction2)
      //const encodedTransactions = new Uint8Array([...encodedTransaction1, ...encodedTransaction2])
      //const encodedTransaction1 = algosdk.encodeUnsignedTransaction(transaction1)
      //const encodedTransaction2 = algosdk.encodeUnsignedTransaction(transaction2)
      //const encodedTransactions = algosdk.encodeUnsignedTransaction([transaction1,transaction2])
      //const assigngroupTx = algosdk.assignGroupID([transaction1,transaction2])
      //const groupTx = algosdk.computeGroupID(assigngroupTx)
      //const encodedTransactions = new Uint8Array([...encodedTransaction, ...encodedTransaction2]);
      const signedTransactions = await signTransactions([txnsToGroup])
      const waitRoundsToConfirm = 4
      const { id } = await sendTransactions(signedTransactions, waitRoundsToConfirm)
      var replyModalId = document.getElementById("replyModal");
      var bodyId = document.getElementById("body");
      var modalBg = document.querySelectorAll(".modal-backdrop.fade.show");
      //console.log('Successfully sent transaction. Transaction ID: ', id);
      toast.success('Successfully sent transaction. Transaction ID: '+id, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
        setTimeout(function(){
        replyModalId?.classList.remove("show");
        bodyId?.classList.remove("modal-open");
        bodyId?.removeAttribute("style");
        modalBg.forEach(box => {
          box.remove();
        });
        txid ? (window.location.href=`/view/${txid}`):(window.location.href=`/view/${id}`)
      }, 3000);
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      //console.error(error)
    }
  }

  if (!activeAddress) {
    return <p>Connect an account first.</p>
  }

  return (
    <div>
      <button type="button" disabled={note ? false : true}
      className="btn btn-primary rounded-pill px-5"
      onClick={() => sendTransaction()}
      >
        Sign &amp; Reply
      </button>
      <ToastContainer />
    </div>
  )
}