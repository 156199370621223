import React, { useState } from "react";
import WalletBar from "../../walletbar/walletbar";
import PostTransaction from "../../../helpers/postTransaction.tsx";
import Warning from "../../../helpers/warning.js";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { encode } from "html-entities";
const ShareModal = ({ activeWallet }) => {
  const [message, setMessage] = useState("");
  const [topics, setTopics] = useState("");
  const [inputText, setInputText] = useState("");
  const [characterLimit] = useState(300);
  // event handler
  const handleChange = (event) => {
    setInputText(event.target.value);
  };
  return (
    <>
      <div
        className="modal fade"
        id="shareModal"
        tabIndex="-1"
        aria-labelledby="shareModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form id="shareform">
              <div className="modal-body p-3 p-lg-4">
                <button
                  type="button"
                  className="btn-close position-absolute top-0 end-0 me-3 mt-3"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div className="mb-4">
                  <label
                    forhtml="speak"
                    className="row align-items-center mb-4 g-3"
                  >
                    <WalletBar
                      wallet={activeWallet}
                      big={false}
                      personal={false}
                    />
                  </label>
                  <div className="position-relative">
                    <div className="border rounded-4 overflow-hidden text-editor">
                      <textarea
                        className="form-control rounded-0 border-0 border-bottom"
                        id="speak"
                        aria-describedby="speakHelp"
                        rows="5"
                        maxLength="300"
                        placeholder="What's on your mind?"
                        value={message}
                        onChange={(e) =>
                          setMessage(e.target.value) + handleChange(e)
                        }
                        role="application"
                        autoFocus={true}
                      ></textarea>
                      <div className="input-group rounded-0 border-0 border-bottom">
                        <span className="input-group-text rounded-0 border-0 border-end">
                          <i className="fa-sharp fa-solid fa-tag"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control rounded-0 border-0"
                          maxLength="20"
                          id="topics"
                          onChange={(e) => setTopics(e.target.value)}
                          placeholder="Topic: E.g. Finance... (optional)"
                        />
                      </div>
                    </div>
                    <div className="bg-none d-flex align-items-center p-1">
                      <div className="position-absolute bottom-0 start-0 p-1">
                        <button
                          className="btn btn-sm btn-dark border-0 text-body-emphasis px-2 py-0 m-0"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#shareEmojiOffCanvas"
                          aria-controls="shareEmojiOffCanvas"
                        >
                          <i className="fa-sharp fa-regular fa-face-smile fa-fw"></i>
                        </button>
                        <div
                          className="offcanvas offcanvas-end"
                          tabIndex="-1"
                          id="shareEmojiOffCanvas"
                          aria-labelledby="shareEmojiOffCanvasLabel"
                        >
                          <div className="offcanvas-header">
                            <h5
                              className="offcanvas-title"
                              id="shareEmojiOffCanvasLabel"
                            >
                              Emojis
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="offcanvas-body">
                            <Picker
                              data={data}
                              searchPosition="top"
                              previewPosition="none"
                              onEmojiSelect={(emoji) =>
                                setMessage(message + " " + emoji.native) +
                                document.getElementById("speak").focus()
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <span className="ms-auto">
                        <span
                          className={`btn btn-sm cursor-none btn-dark border rounded-4 text-body-emphasis px-2 py-0 m-0 ${
                            (inputText.length > characterLimit - 50) &
                            (inputText.length <= characterLimit)
                              ? "bg-warning"
                              : ""
                          } ${
                            inputText.length > characterLimit ? "bg-danger" : ""
                          }`}
                        >
                          {inputText.length}/{characterLimit}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div id="speakHelp">
                    <Warning />
                  </div>
                </div>
                <PostTransaction
                  note={
                    message &&
                    (topics
                      ? "soapblox:" +
                        "topic:" +
                        new Buffer.from(
                          encode(topics, {
                            mode: "nonAsciiPrintableOnly",
                            level: "xml",
                          })
                        ).toString("base64") +
                        ";msg:" +
                        new Buffer.from(
                          encode(message, {
                            mode: "nonAsciiPrintableOnly",
                            level: "xml",
                          })
                        ).toString("base64") +
                        ";"
                      : "soapblox:msg:" +
                        new Buffer.from(
                          encode(message, {
                            mode: "nonAsciiPrintableOnly",
                            level: "xml",
                          })
                        ).toString("base64") +
                        ";")
                  }
                  reply={false}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareModal;
