import React from "react";
const Roadmap = (accountAddress) => {
  return (
    <>
      <div className="py-2 py-lg-5">
        <div id="blox" className="border rounded-4 overflow-hidden">
          <div className="p-3 p-lg-4">
            <h4 className="text-body-emphasis m-0">Roadmap</h4>
          </div>
          <div className="p-5 border-top">
            <div className="d-flex justify-content-between small">
              <div>
                <i className="fa-sharp fa-solid fa-shield-check text-success fa-lg"></i>{" "}
                Completed
              </div>
              <div>
                <i className="fa-solid fa-circle text-info fa-lg"></i> Current
              </div>
              <div>
                <i className="fa-regular fa-circle text-warning fa-lg"></i>{" "}
                Upcoming
              </div>
              <div>
                <i className="fa-regular fa-circle text-danger fa-lg"></i> Hold
              </div>
            </div>
          </div>
          <div className="p-5 border-top">
            <div className="row g-3">
              <div className="col-auto text-center pt-2 position-relative">
                <i className="fa-sharp fa-solid fa-shield-check text-success mt-4 fa-2xl"></i>
              </div>
              <div className="col">
                <div className="p-3 border border-success border-left-success bg-success rounded-4 text-dark">
                  Release platform as it currently stands (MVP).
                  <small className="d-block text-dark text-opacity-50">
                    December 2023
                  </small>
                </div>
              </div>
              <div className="col-12 text-center">
                <i className="fa-sharp fa-light fa-arrow-down"></i>
              </div>
              <div className="col-auto text-center pt-2 position-relative">
                <i className="fa-regular fa-circle text-warning mt-4 fa-2xl"></i>
              </div>
              <div className="col">
                <div className="p-3 border border-left-warning rounded-4 text-body-emphasis">
                  Add functionality for reposts. E.g. Twitter retweets.
                  <small className="d-block text-body text-opacity-50">
                    Thoon...
                  </small>
                </div>
              </div>
              <div className="col-12 text-center">
                <i className="fa-sharp fa-light fa-arrow-down"></i>
              </div>
              <div className="col-auto text-center pt-2 position-relative">
                <i className="fa-regular fa-circle text-warning mt-4 fa-2xl"></i>
              </div>
              <div className="col">
                <div className="p-3 border border-left-warning rounded-4 text-body-emphasis">
                  Add functionality to follow and unfollow users using NFTs.
                  <small className="d-block text-body text-opacity-50">
                    TBC
                  </small>
                </div>
              </div>
              <div className="col-12 text-center">
                <i className="fa-sharp fa-light fa-arrow-down"></i>
              </div>
              <div className="col-auto text-center pt-2 position-relative">
                <i className="fa-regular fa-circle text-warning mt-4 fa-2xl"></i>
              </div>
              <div className="col">
                <div className="p-3 border border-left-warning rounded-4 text-body-emphasis">
                  Create discord bot for feeds.
                  <small className="d-block text-body text-opacity-50">
                    TBC
                  </small>
                </div>
              </div>
              <div className="col-12 text-center">
                <i className="fa-sharp fa-light fa-arrow-down"></i>
              </div>
              <div className="col-auto text-center pt-2 position-relative">
                <i className="fa-regular fa-circle text-warning mt-4 fa-2xl"></i>
              </div>
              <div className="col">
                <div className="p-3 border border-left-warning rounded-4 text-body-emphasis">
                  Figure out a decent way to trigger notifications.
                  <small className="d-block text-body text-opacity-50">
                    TBC
                  </small>
                </div>
              </div>
              <div className="col-12 text-center">
                <i className="fa-sharp fa-light fa-arrow-down"></i>
              </div>
              <div className="col-auto text-center pt-2 position-relative">
                <i className="fa-regular fa-circle text-danger mt-4 fa-2xl"></i>
              </div>
              <div className="col">
                <div className="p-3 border border-left-danger rounded-4 text-body-emphasis">
                  Trash the app if it's not useful.{" "}
                  <span className="text-body">💀💩</span>
                  <small className="d-block text-body text-opacity-50">
                    Hopefully not.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roadmap;
