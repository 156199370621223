import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Assets from "Root/components/assets/assets";
const LatestNFTs = () => {
  const location = useLocation().pathname;
  const user = location.replace("/profile/", "");
  const [walletAdr, setWalletAdr] = useState(false);
  const fetchJson = useCallback(async () => {
    if (user) {
      if (user.includes(".algo")) {
        await axios
          .get(`https://api.nf.domains/nfd/${user}`)
          .then((response) => {
            if (response?.data.depositAccount) {
              setWalletAdr(response.data.depositAccount);
            } else {
              setWalletAdr(user);
            }
          })
          .catch((err) => {
            setWalletAdr(user);
          });
      }
    }
  }, [user]);
  useEffect(() => {
    if (user.includes(".algo")) {
      fetchJson();
    }
  }, [user, fetchJson]);

  return (
    location.includes("/profile/") && (
      <div className="rounded-4 border mb-4">
        <div className="p-3 border-bottom">
          <h4 className="text-body-emphasis m-0">Latest NFTs</h4>
        </div>
        <div className="p-3">
          <Assets wallet={walletAdr} />
        </div>
      </div>
    )
  );
};

export default LatestNFTs;
