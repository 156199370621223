import React, { useState } from "react";
import GiftAlgo from "../../../helpers/giftAlgo.tsx";
const GiftModal = ({ to, name }) => {
  const [amount, setAmount] = useState(10);
  return (
    <>
      <div
        className="modal fade"
        id="giftModal"
        tabIndex="-1"
        aria-labelledby="giftModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <form id="giftform">
              <div className="modal-body p-3 p-lg-4">
                <button
                  type="button"
                  className="btn-close position-absolute top-0 end-0 me-3 mt-3"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div className="mb-4">
                  <label
                    forhtml="gift"
                    className="form-label fw-bold text-body-emphasis mb-4"
                  >
                    <i className="fa-solid fa-hand-holding-heart fa-lg me-2"></i>
                    Donate
                  </label>
                  <p>
                    Support your favorite people directly! Donate a little $ALGO
                    to show your love.
                  </p>
                  <small className="d-block">Recipient:</small>
                  <span className="text-body-emphasis mb-4 d-block">
                    {name}
                  </span>
                  <div className="input-group rounded-4">
                    <input
                      type="number"
                      className="form-control"
                      id="gift"
                      aria-describedby="giftHelp"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      role="application"
                      autoFocus={true}
                    />
                    <span className="input-group-text">$ALGO</span>
                  </div>
                </div>
                <GiftAlgo
                  note={
                    "bgft:" +
                    new Buffer.from(
                      "Someone sent you Love via SoapBlox"
                    ).toString("base64")
                  }
                  to={to}
                  amount={amount * 1000000}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default GiftModal;
