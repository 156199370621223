import React from "react";
const Tos = (accountAddress) => {
  return (
    <>
      <div className="py-2 py-lg-5">
        <div id="blox" className="border rounded-4 overflow-hidden">
          <div className="p-3 p-lg-4">
            <h4 className="text-body-emphasis m-0">Terms</h4>
          </div>
          <div className="p-3 p-lg-4 border-top">
            <p>
              Welcome to SoapBlox! Before you proceed, please read the following
              Terms of Service carefully, as they outline the rules and
              guidelines you must follow while using our platform. By accessing
              and using our platform, you agree to abide by these terms.
            </p>

            <p className="lead text-body-emphasis">User Agreement</p>
            <ol>
              <li>
                By using this platform, you confirm that you are 18 years of age
                or older. If you are under 18, you are not allowed to use our
                platform.
              </li>
              <li>
                You are solely responsible for any content you post, share, or
                interact with on the platform.
              </li>
              <li>
                Your posted content will be stored on the blockchain forever,
                and you will not be able to edit or delete it once posted.
                Please ensure you triple-check what you post.
              </li>
            </ol>

            <p className="lead text-body-emphasis">Decentralized Nature</p>
            <ol>
              <li>
                SoapBlox operates on a the Algorand Blockchain. We do not store
                any of your personal data or content on central servers. Your
                content is distributed across the decentralized network.
              </li>
              <li>
                You (and we) cannot edit or delete any of your content since it
                is stored permanently on the blockchain. Please exercise caution
                and responsibility in what you post.
              </li>
            </ol>

            <p className="lead text-body-emphasis">Content Guidelines</p>
            <ol>
              <li>
                You agree not to post, share, or promote any content that is
                obscene, offensive, defamatory, illegal, or harmful to others in
                any way.
              </li>
              <li>
                Do not post or engage in any sexually explicit or NSFW (Not Safe
                For Work) content.
              </li>
              <li>
                Respect the intellectual property rights of others. Do not share
                copyrighted material without appropriate permissions.
              </li>
              <li>
                Avoid engaging in any activity that violates the privacy or
                security of others.
              </li>
            </ol>

            <p className="lead text-body-emphasis">User Conduct</p>
            <ol>
              <li>
                Treat all users with respect and do not engage in harassment,
                bullying, or any form of harmful behavior towards others.
              </li>
              <li>
                Do not attempt to access or use another user's account without
                their explicit permission.
              </li>
              <li>
                Refrain from any actions that disrupt or harm the functionality
                of the platform or the decentralized network.
              </li>
            </ol>

            <p className="lead text-body-emphasis">
              Liability and Indemnification
            </p>
            <ol>
              <li>
                Our platform and its developers are not liable for any content
                posted or shared by users. Users are solely responsible for
                their actions and content.
              </li>
              <li>
                You agree to indemnify and hold harmless the platform and its
                developers from any claims, damages, or liabilities resulting
                from your use of the platform.
              </li>
            </ol>

            <p className="lead text-body-emphasis">Modification of Terms</p>
            <ol>
              <li>
                We may update or modify these Terms of Service from time to
                time. Users will be notified of any significant changes, and
                continued use of the platform constitutes acceptance of the
                updated terms.
              </li>
            </ol>

            <p className="lead text-body-emphasis">Termination</p>
            <ol>
              <li>
                We reserve the right to terminate or suspend any user account
                that violates these Terms of Service or engages in any harmful
                activity on the platform.
              </li>
              <li>
                That being said, this is a decentralized platform, so we would
                need to write code to specifically block your wallet address
                from showing on the site, although your posts would still be
                stored forever on the Algorand Blockchain.
              </li>
            </ol>

            <p>
              By using SoapBlox, you agree to be bound by these Terms of
              Service. If you do not agree with any part of these terms, please
              refrain from using our platform. Enjoy your experience on our
              platform while respecting the rights and well-being of others!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tos;
