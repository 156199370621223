import React, { useEffect, useState } from "react";
//import { useNavigate } from "react-router-dom";
import algosdk from "algosdk";
import Asset from "../asset/asset";
import {
  DEFAULT_NODE_BASEURL,
  DEFAULT_NODE_TOKEN,
  DEFAULT_NODE_PORT,
} from "@txnlab/use-wallet";
const algodClient = new algosdk.Algodv2(
  DEFAULT_NODE_TOKEN,
  DEFAULT_NODE_BASEURL,
  DEFAULT_NODE_PORT
);
const Assets = ({ wallet }) => {
  const [walletAssets, setWalletAssets] = useState(false);
  const [loading, setLoading] = useState(true);
  const getAssets = async () => {
    if (wallet) {
      if (!wallet.includes(".algo")) {
        const address = wallet;
        const accountInfo = await algodClient.accountInformation(address).do();
        if (!walletAssets) {
          let found_assets = accountInfo.assets.filter(function (found_asset) {
            return (
              found_asset.amount === 1 && found_asset["is-frozen"] === false
            );
          });
          setWalletAssets(found_assets.reverse());
          setLoading(false);
        }
      }
    }
  };
  useEffect(() => {
    if (wallet) {
      if (!wallet.includes(".algo")) {
        getAssets();
      }
    }
    //console.log(walletAssets);
  });
  return (
    <>
      {walletAssets !== false && !loading ? (
        <div className="row g-3 align-items-center">
          {walletAssets.slice(0, 4).map((item) => (
            <Asset id={item["asset-id"]} key={item["asset-id"]} />
          ))}
        </div>
      ) : (
        <div className="p-3 border rounded-4">No recent NFTs...</div>
      )}
    </>
  );
};
export default Assets;
